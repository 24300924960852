<template>
  <div
    class="userguanli"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="ID" width="90"></el-table-column>
        <el-table-column
          prop="memberNo"
          label="用户编号"
          width="100"
        ></el-table-column>
        <el-table-column prop="name" label="姓名" width="90"></el-table-column>
        <el-table-column prop="sex" label="性别" width="90"> </el-table-column>
        <el-table-column prop="idcard" label="身份证号" width="170">
        </el-table-column>
        <el-table-column prop="phone" label="联系方式" width="160">
        </el-table-column>
        <el-table-column prop="address" label="地址" width="150">
        </el-table-column>
        <el-table-column prop="bindList" label="绑定设备列表" width="170">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              type="danger"
              @click="nameUser(scope.$index, scope.row)"
              v-for="(item, index) in scope.row.bindList"
              :key="index"
              >{{ item }}
            </el-button> -->
            <el-button
              class="details"
              size="mini"
              v-for="(item, index) in scope.row.bindList"
              :key="index"
              @click="nameUser(scope.$index, scope.row, item)"
              >{{ item }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="caozuo" label="操作" width="240">
          <template slot-scope="scope">
            <el-button
              class="details"
              size="mini"
              @click="changeUser(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              class="details"
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
              >删除</el-button
            >
            <el-button
              class="details"
              size="mini"
              @click="details(scope.$index, scope.row)"
              >绑定设备</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="totals">
      <!-- 左 -->
      <div class="left">
        <p>
          总用户数
          <span class="spanto">{{ totalCount }}</span
          >人
        </p>
      </div>
      <!-- 右 -->
      <div class="total">
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :current-page="pageCount"
            layout="total, prev, pager, next"
            :total="totalCount"
          >
          </el-pagination>
        </div>
        <!-- 按钮 -->
        <div class="buttons">
          <el-button type="text" @click="add">添加用户</el-button>
        </div>
      </div>
    </div>

    <!-- 增加弹框-->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="clearUserForm"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label="用户编号" prop="name">
            <el-input
              v-model="ruleForm.memberNo"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" style="width: 196px"></el-input>
          </el-form-item>
          <!-- <el-form-item label="是否为客服" prop="">
            <el-select
              v-model="ruleForm.cusSerSign"
              placeholder="请选择"
              @change="getUserValueCus(ruleForm.cusSerSign)"
            >
              <el-option
                v-for="item in optionsCus"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="性别" prop="">
            <el-select v-model="ruleForm.sex" placeholder="请选择">
              <el-option
                v-for="item in optionsSex"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份证号" prop="">
            <el-input v-model="ruleForm.idcard" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="">
            <el-input v-model="ruleForm.phone" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="">
            <el-input
              v-model="ruleForm.address"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="绑定设备" prop="">
            <el-input v-model="ruleForm.imei" style="width: 196px"></el-input>
          </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearUserForm">取 消</el-button>
          <el-button type="primary" @click="addUserList">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 修改弹框-->
    <div class="accountadd">
      <el-dialog
        title="编辑"
        :visible.sync="dialogVisibleChange"
        width="50%"
        :before-close="clearUserFormChange"
      >
        <el-form
          :model="ruleForm1"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label="用户编号" prop="name">
            <el-input
              v-model="ruleForm1.memberNo"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm1.name" style="width: 196px"></el-input>
          </el-form-item>
          <!-- <el-form-item label="是否为客服" prop="">
            <el-select
              v-model="ruleForm.cusSerSign"
              placeholder="请选择"
              @change="getUserValueCus(ruleForm.cusSerSign)"
            >
              <el-option
                v-for="item in optionsCus"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="性别" prop="">
            <el-select v-model="ruleForm1.sex" placeholder="请选择">
              <el-option
                v-for="item in optionsSex"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份证号" prop="">
            <el-input
              v-model="ruleForm1.idcard"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="">
            <el-input v-model="ruleForm1.phone" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="">
            <el-input
              v-model="ruleForm1.address"
              style="width: 196px"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearUserFormChange">取 消</el-button>
          <el-button type="primary" @click="changeUserLs">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 绑定设备弹框-->
    <div class="accountadd">
      <el-dialog title="绑定设备" :visible.sync="dialogVisible1" width="50%">
        <el-form
          :model="ruleForm2"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label="IMEI" prop="name">
            <el-input v-model="ruleForm2.imei" style="width: 196px"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="bindEqu">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 二次弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisibleTwo" width="30%">
      <span
        >确认删除 <span style="color: #0085d0">{{ memName }}</span> 吗？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTwo = false">取 消</el-button>
        <el-button type="primary" @click="deleteTwo">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisibleOne" width="30%">
      <span
        >确认解绑
        <span style="color: #0085d0">{{ imeiNum }}</span> 设备吗？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleOne = false">取 消</el-button>
        <el-button type="primary" @click="deleteOne">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShan } from "../../../api1/shanConfig";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      twoID: "",
      // 弹框
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleChange: false,
      dialogVisibleTwo: false,
      dialogVisibleOne: false,
      optionsSex: [
        {
          code: "1",
          name: "男",
        },
        {
          code: "0",
          name: "女",
        },
      ],
      isShow: true,
      title: "添加用户",
      ruleForm: {},
      ruleForm1: {},
      ruleForm2: {},
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      optionsCus: [],
      value: "",
      tableData: [],
      rules: {
        userPass: [
          { required: true, message: "请输入ID名称", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
      },
      memId: null,
      eqMem: null,
      imeiId: null,
      imeiNum: null,
      memName: "",
    };
  },
  // components: {
  //   rolemanage,
  //   addaccount
  // },
  created() {
    this.menulist();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    nameUser(index, row, item) {
      console.log("44:", index, row, item);
      this.dialogVisibleOne = true;
      this.imeiNum = item;
      this.imeiId = row.id;
      // this.$confirm("是否解绑该设备?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     apiShan.getunBind({ imei: item, memberId: row.id }).then((res) => {
      //       if (res.data.code === 200) {
      //         this.$message({
      //           type: "success",
      //           message: "绑定成功!",
      //         });
      //         this.menulist();
      //       } else {
      //         this.$message.error(res.data.msg);
      //       }
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消",
      //     });
      //   });
    },
    deleteOne() {
      apiShan
        .getunBind({ imei: this.imeiNum, memberId: this.imeiId })
        .then((res) => {
          if (res.data.code === 200) {
            this.dialogVisibleOne = false;
            this.$message({
              type: "success",
              message: "解绑成功!",
            });
            this.menulist();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    bindEqu() {
      apiShan
        .getbindAla({ imei: this.ruleForm2.imei, memberId: this.eqMem })
        .then((res) => {
          console.log("绑定：", res.data);
          if (res.data.code == 200) {
            this.dialogVisible1 = false;
            this.$message({
              type: "success",
              message: "绑定成功!",
            });
            this.menulist();
            this.ruleForm2 = {};
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //用户列表
    menulist() {
      apiShan
        .getala({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          // startDate:
          //   this.overallForm.time == null ? "" : this.overallForm.time[0],
          // endDate:
          //   this.overallForm.time == null ? "" : this.overallForm.time[1],
          // status: this.SerachInput.status,
          // type: this.SerachInput.userInfo,
          // value: this.SerachInput.valueInfo,
          // centerType: this.SerachInput.centerType,
          // sysCreate: this.SerachInput.sysCreate,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.tableData.forEach((ele) => {
              if (ele.sex == "0") {
                ele.sex = "女";
              } else {
                ele.sex = "男";
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //添加用户
    add() {
      this.dialogVisible = true;
    },
    //添加用户
    addUserList() {
      let reg = /^\d{17}[\dX]$/;
      if (!reg.test(this.ruleForm.idcard) || this.ruleForm.idcard == "") {
        this.$message.error("请输入正确的身份证号或不能为空");
        return false;
      }
      if (
        !/^1[34578]\d{9}$/.test(this.ruleForm.phone) ||
        this.ruleForm.phone == ""
      ) {
        this.$message.error("手机号码不正确，请重新添加或不能为空");
        return false;
      }
      apiShan.getaddUser(this.ruleForm).then((res) => {
        console.log("增加：", res.data);
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.$message({
            type: "success",
            message: "增加成功!",
          });
          this.menulist();
          this.ruleForm = {};
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //编辑用户
    changeUser(index, row) {
      console.log(index, row);
      this.dialogVisibleChange = true;
      this.ruleForm1 = row;
    },
    //编辑的确定
    changeUserLs() {
      let reg = (reg = /^\d{17}[\dX]$/);
      if (!reg.test(this.ruleForm1.idcard) || this.ruleForm1.idcard == "") {
        this.$message.error("请输入正确的身份证号或不能为空");
        return false;
      }
      if (
        !/^1[34578]\d{9}$/.test(this.ruleForm1.phone) ||
        this.ruleForm1.phone == ""
      ) {
        this.$message.error("手机号码不正确，请重新添加或不能为空");
        return false;
      }
      apiShan.getupAla(this.ruleForm1).then((res) => {
        console.log("修改：", res.data);
        if (res.data.code == 200) {
          this.dialogVisibleChange = false;
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.menulist();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //删除用户
    handleEdit(index, row) {
      console.log(index, row);
      this.memId = row.id;
      this.memName = row.name;
      this.dialogVisibleTwo = true;
    },
    deleteTwo() {
      apiShan.getdeAla({ memberId: this.memId }).then((res) => {
        console.log("删除：", res.data);
        if (res.data.code == 200) {
          this.dialogVisibleTwo = false;
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.menulist();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    details(index, row) {
      // console.log(index, row);
      this.eqMem = row.id;
      this.dialogVisible1 = true;
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.menulist();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.menulist();
    },
    clearUserFormChange() {
      this.dialogVisibleChange = false;
    },
    clearUserForm() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.userguanli {
  // 搜索
  .sousuo {
    // background: red;
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .div1 {
      margin-right: 20px;
      .inputs {
        width: 231px;
        height: 33px;
        border: 1px solid #e5e5e5;
        margin-left: 5px;
      }
      .inputs:focus {
        outline: 1px solid #e5e5e5; //边框不用border，用outline
      }
      span {
        font-size: 14px;
        color: #909399;
        font-weight: 700;
      }
      .select {
        margin-left: 20px;
      }
    }
    .imgs {
      width: 49px;
    }
  }
  //   table
  .tables {
    position: relative;
    margin-left: 25px;
    margin-right: 40px;

    .tingyong {
      color: rgb(97, 97, 245);
    }
    .buts {
      width: 36px;
      height: 27px;
      margin: 0 10px;
    }
    .el-table {
      // max-height: 500px;
      // overflow: hidden;
      tr {
        th {
          font-size: 14px;
          border: none;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #909399;
        }
        td {
          border: none;
          text-align: center;
          font-size: 14px;
          color: #606266;
        }
      }
      .details {
        height: 27px;
        border-radius: 15px;
      }
    }
    .el-table::before {
      height: 0;
    }
    // .details {
    //   height: 30px;
    //   position: absolute;
    //   top: 12px;
    //   right: 1px;
    //   border-radius: 15px;
    // }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  .totals {
    display: flex;
    justify-content: space-between;
    .left {
      p {
        font-size: 16px;
        display: inline-block;
        margin-right: 80px;

        margin-left: 50px;

        margin-top: 20px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      margin-right: 50px;
      // 按钮
      .buttons {
        display: flex;
        .el-button {
          width: 130px;
          height: 40px;
          line-height: 40px;
          border-radius: 25px;
          margin-top: 20px;
          margin-right: 20px;
          background: #0085d0;
          color: #ffffff;
          font-size: 16px;
          padding: 0px;
        }
      }
      // page
      .pages {
        display: flex;
        align-items: center;
        .el-pagination {
          padding: 0;
          img {
            display: inline-block;
          }
          .lefts {
            margin-right: 25px;
          }
          .rights {
            float: right;
            margin-left: 25px;
          }
        }
      }
    }
  }
  // .el-input__inner {
  //   width: 50%;
  // }
  .lizhi {
    margin-left: -10px;
  }
  .account .el-input__inner {
    border: 1px solid #fff;
  }
  .el-form-item__label {
    margin-left: 60px;
  }
  .workState {
    margin: 70px 120px;
    span {
      margin-right: 15px;
    }
  }
  .sexState {
    position: absolute;
    right: 173px;
    top: 245px;
    span {
      margin-right: 15px;
    }
  }
  .jigouState {
    position: absolute;
    right: 173px;
    top: 180px;
    span {
      margin-right: 15px;
    }
  }
  .jueseState {
    position: absolute;
    left: 133px;
    top: 245px;
    span {
      margin-right: 15px;
    }
  }
  .dengState {
    position: absolute;
    left: 105px;
    top: 180px;
    span {
      margin-right: 15px;
    }
  }
  .workState1 {
    margin: 130px 120px;
    span {
      margin-right: 15px;
    }
  }
  // 弹框
  /deep/.el-dialog {
    border-radius: 20px;
  }
  /deep/.el-dialog__title {
    line-height: 24px;
    font-size: 18px;
    color: #0085d0;
  }
  // .el-dialog__header {
  //   background: #eaeaea;
  //   border-radius: 18px 18px 0 0;
  // }
  // .el-dialog__close {
  //   color: transparent;
  //   background-image: url("../../assets/images/close.png");
  //   background-repeat: no-repeat;
  //   background-size: 14px;
  // }
  // .el-dialog__body {
  //   background: #eaeaea;
  //   border-radius: 0 0 18px 18px;
  //   padding: 10px 0px 0 0px;
  // }
  .accountdd {
    .el-dialog__header {
      background: #ffffff;
      border-radius: 18px 18px 0 0;
    }
    .el-dialog__body {
      background: #ffffff;
      border-radius: 0 0 18px 18px;
      padding: 10px 0px 0 0px;
    }
  }
  /deep/.el-input__inner {
    border-radius: 0;
    height: 33px !important;
    line-height: 33px !important;
  }
  /deep/.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 33px !important;
  }
  /deep/.el-form-item__label {
    color: #909399;
    text-align: center;
  }
  /deep/.el-dialog__body {
    padding: 0;
    margin-left: 2%;
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
  /deep/.el-button + .el-button {
    margin-left: 5px;
  }
}
</style>